﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Admin, User } from '../_models';
import { AlertService } from '../_services/alert.service';
import {error} from 'protractor';


@Injectable()
export class UserService {
    private apiUrlAdmin = environment.apiUrl + '/admins';  // URL to web api
    private apiUrl = environment.apiUrl + '/users';  // URL to web api
    constructor(private http: HttpClient, private alertService: AlertService) { }
    getAdmins (offset: number = 0, limit: number = 10, keyword:string = '', sortBy:string = 'name', sortType:string = 'asc'): Observable<any> {

        let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `${limit}`).set('page', `${offset}`);

        //let params = {limit:limit,page:offset,sortBy:'id',keyword: ''} ;
        if(keyword != ''){
          params = params.append('keyword', keyword);
        }
        params = params.append('sortBy', `${sortBy}`);
        params = params.append('sortType', `${sortType}`);
        return this.http.get<any>(this.apiUrlAdmin + '/filter' , {params:params, headers: httpOptions.headers})
          .pipe(
              map(response => {
                if (response && response.status === 'success') {
                  if (sortBy === 'roles') {
                    if (response.data.admins) {
                      if (sortType === 'asc') {
                        response.data.admins.sort((a, b) => {
                          return (a.roles[0] > b.roles[0]) ? -1 : ((a.roles[0] < b.roles[0]) ? 1 : 0);
                        });
                      } else {
                        response.data.admins.sort((a, b) => {
                          return (a.roles[0] < b.roles[0]) ? -1 : ((a.roles[0] > b.roles[0]) ? 1 : 0);
                        });
                      }
                    }
                  }

                  return response.data;
                }
                return [];
            },
            catchError(this.handleError('getAdmin', [])))
          );
    }
    getAdmin(id: number): Observable<any> {
        return this.http.get<any>(this.apiUrlAdmin + '/' + id).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getAdmin Error')))
        );
    }
    addAdmin (item: any): Observable<any> {
        return this.http.post<Admin>(this.apiUrlAdmin, item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The admin has been added`,true)),
          catchError(this.handleError<any>('addAdmin'))
        );
    }
    updateAdmin (item: Admin): Observable<any> {
        return this.http.patch(this.apiUrlAdmin + '/' + item.id, item, httpOptions).pipe(
          tap((item: Admin) => this.alertService.success(`The admin has been updated`)),
          catchError(this.handleError<any>('updateAdmin'))
        );
    }
    deleteAdmin(id: number): Observable<any> {
        return this.http.delete<any>(this.apiUrlAdmin + '/' + id).pipe(
          map(response => {return null;},
          catchError(this.handleError<any>('deleteAdmin Error')))
        );
    }

    getUsers (offset: number = 0, limit: number = 10, keyword:string = '', sortBy:string = 'name', sortType:string = 'asc'): Observable<any> {
        let params = new HttpParams({encoder: new CustomURLEncoder() }).set('limit', `${limit}`).set('page', `${offset}`);

        //let params = {limit:limit,page:offset,sortBy:'id',keyword: ''} ;
        if(keyword != ''){
          params = params.append('keyword', keyword);
        }
        params = params.append('sortBy', `${sortBy}`);
        params = params.append('sortType', `${sortType}`);
        console.log(this.apiUrl + '/filter' )
        return this.http.get<any>(this.apiUrl + '/filter' , {params:params})
          .pipe(
              map(response => {
                if (response && response.status == 'success') {
                  console.log(response.data)
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError('getUser', [])))
          );
    }

    getAllUsers(sortBy: string = 'name', sortType: string = 'asc', requireAcc: string = '0'): Observable<any> {
      let params = new HttpParams({encoder: new CustomURLEncoder() });
      params = params.append('parent', 'true');
      params = params.append('sortBy', `${sortBy}`);
      params = params.append('sortType', `${sortType}`);
      params = params.append('limit', '200');
      params = params.append('status', '1');
      params = params.append('requireAccount', `${requireAcc}`);
      return this.http.get<any>(this.apiUrl + '/filter', {params: params})
        .pipe(
          map(response => {
              if (response && response.status === 'success') {
                return response.data;
              }
              return [];
            },
            catchError(this.handleError('getUser', [])))
        );
    }

    getAllInspectableUser(status: string = '-1'): Observable<any> {
      return this.http.get<any>(this.apiUrl + '/inspectable?status=' + status, {})
        .pipe(
          map(response => {
              if (response && response.status === 'success') {
                return response.data.user;
              }
              return [];
            },
            catchError(this.handleError('getUser', [])))
        );
    }

    getUser(id: number): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/' + id).pipe(
            map(response => {
                if (response && response.status == 'success') {
                    return response.data;
                }
                return [];
            },
            catchError(this.handleError<any>('getUser Error')))
        );
    }
    addUser (item: any): Observable<any> {
        return this.http.post<User>(this.apiUrl, item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The user has been added`,true)),
          catchError(this.handleError<any>('addUser'))
        );
    }
    cloneUser(item: any): Observable<any> {
      const url = "http://api-admin.pr.sang.net/api/users/clone";
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    
      console.log('Sending request to:', url, 'with data:', item);
      // cloneUser (item: any): Observable<any> {
        return this.http.post<User>(this.apiUrl  + '/clone', item, httpOptions).pipe(
          tap((item: any) => this.alertService.success(`The user has been added`,true)),
          catchError(this.handleError<any>('cloneuser'))
        );
      // }
    
      // return this.http.post<User>(url, item, httpOptions).pipe(
      //   tap((response: any) => this.alertService.success(`The user has been added`, true)),
      //   catchError((error) => {
      //     console.error('Error occurred during cloneUser:', error);
      //     return this.handleError<any>('cloneuser');
      //   })
      // );
    }

    updateUser (item: User): Observable<any> {
        return this.http.patch(this.apiUrl + '/' + item.id, item, httpOptions).pipe(
          tap((item: User) => this.alertService.success(`The user has been updated`)),
          catchError(this.handleError<any>('updateUser'))
        );
    }
    deleteUser(id: number): Observable<any> {
        return this.http.delete<any>(this.apiUrl + '/' + id).pipe(
          map(response => {return null;},
          catchError(this.handleError<any>('deleteUser Error')))
        );
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          //this.alertService.error(`UserService: ${operation} failed: ${error}`);
          this.alertService.error(`${error}`);
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }

  sendCodeToMobile(userId): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/sendCodeToMobile', {'userId' : userId})
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError('Get Link Roso', [])))
      );
  }
  getPrecomputeScript(userId): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/getPrecomputeScript', {'userId' : userId})
      .pipe(
        map(response => {
            if (response && response.status === 'success') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError('Get Link Roso', [])))
      );
  }

}
