import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { httpOptions, CustomURLEncoder } from '../_helpers/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root'
})

export class DataInspectionService {
    constructor(private http: HttpClient, private alertService: AlertService) { }

    public getResult(form) {
      let inspectionURL = {
        'PRODUCTS_IN_ACCOUNT': 'PIA_get/',
        'PRODUCTS_IN_ACCOUNT_LIGHT': 'PIAL_get/',
        'GAINS_IN_ACCOUNT': 'GIA_get/',
        'PERFORMANCES_AND_CONTRIBUTIONS': 'PAC_get/',
        'PERFORMANCES_AND_CONTRIBUTIONS_CCY': 'PACC_get/',
        'PERFORMANCES_AND_CONTRIBUTIONS_LOCAL': 'PACL_get/',
        'NEW_PERFORMANCES_AND_CONTRIBUTIONS': 'NEW_PAC_get/',
        'NEW_PERFORMANCES_AND_CONTRIBUTIONS_CCY': 'NEW_PACC_get/',
        'NEW_PERFORMANCES_AND_CONTRIBUTIONS_LOCAL': 'PACL_get/',
      };
      let PRWebURL = environment.PRWebURL + 'calculator/' + inspectionURL[form['typeCalculate']];
      return this.http.post<any>(`${PRWebURL}`, form)
        .pipe(
          timeout(3600000),
          map(response => {
            if (response && response.data !== '') {
              return response.data;
            }
            return [];
          },
          catchError(this.handleError()))
        );
    }

    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        console.log(`${error}`);
        this.alertService.error(`${error}`);
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
  }
